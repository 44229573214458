import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/app-check";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};


if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const appCheck = firebase.appCheck();
const firestore = firebase.firestore();

appCheck.activate(
    new firebase.appCheck.ReCaptchaEnterpriseProvider(
        process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITE_KEY
    ),
    true // True = allow auto-refresh
)
export {
    firestore
}
