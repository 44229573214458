import React, {useEffect, useState} from 'react'

import { Helmet } from 'react-helmet'
import { firestore } from '../firebase/config';
import AppComponent from '../components/component'
import './home.css'

const Home = (props) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleFormSubmission = (e) => {
    document.getElementById('contactformsubmitbtn').disabled = true;
    const name = document.getElementById('contactname');
    const email = document.getElementById('contactemail');
    const inquiryType = document.getElementById('contactprojecttype');
    const inquiryDescription = document.getElementById('contactdescription');

   const validateEmail = (email) => {
     let emailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
     if (email.match(emailFormat)) {
       return true;
     } else {
       window.alert("Please Enter a Valid Email Address");
       return false;
     }
   };

    if (!String(name.value).length > 0) {
      window.alert('Please Enter Name');
      document.getElementById('contactformsubmitbtn').disabled = false;
      return;
    }

        else if (!validateEmail(email.value)) {
        document.getElementById('contactformsubmitbtn').disabled = false;
        return;
        }
    else if (!inquiryType.value) {
      window.alert('Please Select Inquiry Type');
      document.getElementById('contactformsubmitbtn').disabled = false;
      return;
    }
      else if (!String(inquiryDescription.value).length > 0) {
      window.alert('Please Provide Inquiry Description');
      document.getElementById('contactformsubmitbtn').disabled = false;
      return;
    }
    const date = new Date();
      const formattedDate = new Date().toLocaleString('en-US', {
      timeZone: 'America/New_York',
    });
    const formsRef = firestore.collection('Form Submissions');
       return formsRef.add({
         to: email.value,
         bcc: "info@javax.tech",
/*         message: {
           subject: "Website Inquiry",
           text: `${name.value} has submitted a website inquiry on ${date}. The inquiry type is: ${inquiryType.value} and their inquiry description is: ${inquiryDescription.value}. Their email address is: ${email.value}.`
         },*/
         template: {
           name: "WebsiteInquiry",
           data: {
             name: name.value,
             email: email.value,
             inquiryType: inquiryType.value,
             inquiryDescription: inquiryDescription.value,
             dateTime: formattedDate,
           },
         },
        name: name.value,
        email: email.value,
        inquiryType: inquiryType.value,
        inquiryDescription: inquiryDescription.value,
        date: {
          date: date,
          timestamp: date.getTime()
        }
      }).then((res) => {
        setFormSubmitted((true));
      //clear form data
      name.value = "";
      email.value = "";
      inquiryType.value = null;
      inquiryDescription.value = "";
      //console.log(`Form successfully submitted: ${res}`);
      document.getElementById('contactformsubmitbtn').disabled = false;
      return false;
    }).catch((e) => {
      console.error(`Error submitting form: ${e}`);
      document.getElementById('contactformsubmitbtn').disabled = false;
      return false;
    });
  }

  return (
      <div className="home-container">
        <Helmet>
          <title>javaX.tech</title>
          <meta property="og:title" content="javaX.tech" />
        </Helmet>
        <div data-role="Header" className="home-navbar-container">
          <div className="home-navbar">
            <div className="home-container01">
              <a href="https://javax.tech" className="home-heading">
                javaX.tech
              </a>
              <div className="home-container02">
                <span className="home-text">Buffalo, NY</span>
              </div>
            </div>
            <div className="home-links-container">
              <span className="home-link navbar-Link">About</span>
              <span className="home-link01 navbar-Link">Experience</span>
              <span className="home-link02 navbar-Link">Portofolio</span>
              <span className="home-link03 navbar-Link">Contact</span>
            </div>
            <div className="home-container03">
              <a href="https://javax.tech" className="home-link04">
                <img
                    alt="image"
                    src="https://firebasestorage.googleapis.com/v0/b/javax-969b9.appspot.com/o/Website%20Images%2FStudio-Project.png?alt=media&amp;token=f2f4b0af-06ba-4c5d-8768-77edb80f3422"
                    className="home-image"
                />
              </a>
              <a
                  href="mailto:info@javax.tech?subject=Website Inquiry"
                  className="home-link05"
              >
                info@javax.tech
              </a>
            </div>
            <div data-type="MobileMenu" className="home-mobile-menu">
              <div className="home-container04">
                <span className="card-Heading home-heading1">Logo</span>
                <div data-type="CloseMobileMenu" className="home-close-menu">
                  <svg viewBox="0 0 1024 1024" className="home-icon">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <div className="home-links-container1">
                <span className="home-link06 navbar-Link">About</span>
                <span className="home-link07 navbar-Link">Experience</span>
                <span className="home-link08 navbar-Link">Portofolio</span>
                <span className="navbar-Link">Contact</span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-section-separator"></div>
        <div className="home-section-separator1"></div>
        <div className="home-container05">
          <div className="home-hero">
            <div className="home-hero-text-container">
              <h1 className="home-heading2 section-Heading">
                I build solutions that solve technologies most advanced problems
                and requirements.
              </h1>
              <span className="home-text01 section-Text">
              Mobile Applications (Android / iOS), Websites, Databases, API
              Integrations, CRM Systems, VOIP Systems
            </span>
              <div className="home-cta-btn-container">
                <button className="home-cta-btn anchor button">
                  <span className="home-text02">MY WORK</span>
                </button>
                <button className="home-cta-btn1 button anchor">ABOUT ME</button>
              </div>
            </div>
            <img
                alt="image"
                src="https://firebasestorage.googleapis.com/v0/b/javax-969b9.appspot.com/o/Website%20Images%2FC46BE9E8-E577-40AC-9A89-7C1C17689CF2_4_5005_c.jpeg?alt=media&amp;token=18e0dab2-6561-48fb-a595-86cfd8eec385"
                className="home-image1"
            />
          </div>
        </div>
        <div className="home-features">
          <div className="home-heading-container">
            <h2 className="home-text03 section-Heading">Deliverables</h2>
            <span className="home-text04 section-Text">
            I am extremely flexible and diverse in what I can do with my
            experience as a Full Stack Developer. Fact of the matter is, if it
            needs to be programmed, I&apos;m on it. From Servers, to Desktop
            Applications, Mobile Applications, API Integrations, CRM System
            Development, VOIP Systems, Google Workspace, Microsoft, I have you
            covered.
          </span>
          </div>
          <div className="home-cards-container">
            <div className="home-card">
              <div className="home-icon-container">
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M854 768v-384h-172v384h172zM640 554v-170h-470v170h470zM640 768v-170h-470v170h470zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
              </div>
              <div className="home-content-container">
                <span className="home-heading3 card-Heading">Websites</span>
                <span className="home-text05 card-Text">
                Custom Website Development
              </span>
              </div>
            </div>
            <div className="home-card1">
              <div className="home-icon-container1">
                <svg
                    viewBox="0 0 438.85714285714283 1024"
                    className="home-icon04"
                >
                  <path d="M265.143 804.571c0-25.143-20.571-45.714-45.714-45.714s-45.714 20.571-45.714 45.714 20.571 45.714 45.714 45.714 45.714-20.571 45.714-45.714zM384 713.143v-402.286c0-9.714-8.571-18.286-18.286-18.286h-292.571c-9.714 0-18.286 8.571-18.286 18.286v402.286c0 9.714 8.571 18.286 18.286 18.286h292.571c9.714 0 18.286-8.571 18.286-18.286zM274.286 228.571c0-5.143-4-9.143-9.143-9.143h-91.429c-5.143 0-9.143 4-9.143 9.143s4 9.143 9.143 9.143h91.429c5.143 0 9.143-4 9.143-9.143zM438.857 219.429v585.143c0 40-33.143 73.143-73.143 73.143h-292.571c-40 0-73.143-33.143-73.143-73.143v-585.143c0-40 33.143-73.143 73.143-73.143h292.571c40 0 73.143 33.143 73.143 73.143z"></path>
                </svg>
              </div>
              <div className="home-content-container1">
              <span className="home-heading4 card-Heading">
                Mobile Applications
              </span>
                <span className="home-text06 card-Text">
                Custom Mobile Application Development
              </span>
              </div>
            </div>
            <div className="home-card2">
              <div className="home-icon-container2">
                <svg viewBox="0 0 1097.142857142857 1024" className="home-icon06">
                  <path d="M352.571 799.429l-28.571 28.571c-7.429 7.429-18.857 7.429-26.286 0l-266.286-266.286c-7.429-7.429-7.429-18.857 0-26.286l266.286-266.286c7.429-7.429 18.857-7.429 26.286 0l28.571 28.571c7.429 7.429 7.429 18.857 0 26.286l-224.571 224.571 224.571 224.571c7.429 7.429 7.429 18.857 0 26.286zM690.286 189.714l-213.143 737.714c-2.857 9.714-13.143 15.429-22.286 12.571l-35.429-9.714c-9.714-2.857-15.429-13.143-12.571-22.857l213.143-737.714c2.857-9.714 13.143-15.429 22.286-12.571l35.429 9.714c9.714 2.857 15.429 13.143 12.571 22.857zM1065.714 561.714l-266.286 266.286c-7.429 7.429-18.857 7.429-26.286 0l-28.571-28.571c-7.429-7.429-7.429-18.857 0-26.286l224.571-224.571-224.571-224.571c-7.429-7.429-7.429-18.857 0-26.286l28.571-28.571c7.429-7.429 18.857-7.429 26.286 0l266.286 266.286c7.429 7.429 7.429 18.857 0 26.286z"></path>
                </svg>
              </div>
              <div className="home-content-container2">
              <span className="home-heading5 card-Heading">
                Custom Programming
                <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                />
              </span>
                <span className="home-text07 card-Text">
                API Integrations, Google Workspace, Google Cloud Platform,
                Firebase, Salesforce, Custom Application Development
              </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-about">
          <div className="home-max-content-width-container">
            <div className="home-heading-container1">
              <h1 className="section-Heading home-text08">
                Professional experience
              </h1>
            </div>
            <div className="home-container06">
              <h1 className="home-text09">Languages</h1>
              <h1 className="home-text10">Platforms</h1>
            </div>
            <div className="home-content-container3">
              <div className="home-about-1">
                <div className="home-container07">
                  <svg viewBox="0 0 1024 1024" className="home-icon08">
                    <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                  </svg>
                  <span className="home-text11">
                  <span>Java</span>
                  <br></br>
                  <span>JavaScript</span>
                  <br></br>
                  <span>node.JS</span>
                  <br></br>
                </span>
                </div>
                <div className="home-container08">
                  <svg viewBox="0 0 1024 1024" className="home-icon10">
                    <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                  </svg>
                  <span className="home-text18 card-Text">
                  <span>React</span>
                  <br></br>
                  <span>React-Native</span>
                </span>
                </div>
                <div className="home-container09">
                  <svg viewBox="0 0 1024 1024" className="home-icon12">
                    <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                  </svg>
                  <span className="home-text22">
                  <span className="card-Text">HTML</span>
                  <br></br>
                  <span>CSS</span>
                  <br></br>
                </span>
                </div>
              </div>
              <div className="home-about-11">
                <div className="home-container10">
                  <div className="home-container11">
                    <svg viewBox="0 0 1024 1024" className="home-icon14">
                      <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                    </svg>
                    <span className="home-text27 card-Text">
                    <br></br>
                    <span>
                      <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                      />
                    </span>
                  </span>
                  </div>
                  <span className="home-text30">
                  <span>Google Cloud Platform</span>
                  <br></br>
                </span>
                </div>
                <div className="home-container12">
                  <svg viewBox="0 0 1024 1024" className="home-icon16">
                    <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                  </svg>
                  <span className="home-text33 card-Text">Firebase</span>
                </div>
                <div className="home-container13">
                  <svg viewBox="0 0 1024 1024" className="home-icon18">
                    <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                  </svg>
                  <span className="home-text34">
                  <span className="card-Text">Google WorkSpace</span>
                  <br></br>
                </span>
                </div>
              </div>
            </div>
            <div className="home-container14">
              <h1 className="home-text37">CRM Systems</h1>
              <h1 className="home-text38">Services</h1>
            </div>
            <div className="home-content-container4">
              <div className="home-about-12">
                <div className="home-container15">
                  <svg viewBox="0 0 1024 1024" className="home-icon20">
                    <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                  </svg>
                  <span className="home-text39 card-Text">
                  <span>Salesforce</span>
                  <br></br>
                </span>
                </div>
                <div className="home-container16">
                  <svg viewBox="0 0 1024 1024" className="home-icon22">
                    <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                  </svg>
                  <span className="home-text42 card-Text">
                  Salesforce Integrations
                </span>
                </div>
                <div className="home-container17">
                  <svg viewBox="0 0 1024 1024" className="home-icon24">
                    <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                  </svg>
                  <span className="home-text43 card-Text">
                  <span>Salesforce Custom Development</span>
                  <br></br>
                </span>
                </div>
              </div>
              <div className="home-about-13">
                <div className="home-container18">
                  <div className="home-container19">
                    <svg viewBox="0 0 1024 1024" className="home-icon26">
                      <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                    </svg>
                    <span className="home-text46 card-Text">
                    <br></br>
                    <span>
                      <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                      />
                    </span>
                  </span>
                  </div>
                  <span className="home-text49 card-Text">
                  <span>Front-End Development</span>
                  <br></br>
                </span>
                </div>
                <div className="home-container20">
                  <svg viewBox="0 0 1024 1024" className="home-icon28">
                    <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                  </svg>
                  <span className="home-text52 card-Text">
                  Back-End Development
                </span>
                </div>
                <div className="home-container21">
                  <svg viewBox="0 0 1024 1024" className="home-icon30">
                    <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                  </svg>
                  <span className="home-text53 card-Text">
                  <span>UI / UX Design</span>
                  <br></br>
                </span>
                </div>
                <div className="home-container22">
                  <svg viewBox="0 0 1024 1024" className="home-icon32">
                    <path d="M170 512q0-140 101-241t241-101 241 101 101 241-101 241-241 101-241-101-101-241z"></path>
                  </svg>
                  <span className="home-text56 card-Text">
                  <span>API Integrations</span>
                  <br></br>
                  <span>API Development</span>
                  <br></br>
                </span>
                </div>
              </div>
            </div>
            <button className="home-button anchor button">CHECK MY WORK</button>
          </div>
        </div>
        <div className="home-section-separator2"></div>
        <div className="home-clients">
          <div className="home-heading-container2">
            <h1 className="home-text61 section-Heading">Clients</h1>
            <span className="home-text62 section-Text">
            Here we will put your clients&apos; or partners&apos; logos
          </span>
          </div>
          <div className="home-logo-container">
            <img
                alt="image"
                src="/playground_assets/default-img.svg"
                className="home-logo"
            />
            <img
                alt="image"
                src="/playground_assets/default-img.svg"
                className="home-logo1"
            />
            <img
                alt="image"
                src="/playground_assets/default-img.svg"
                className="home-logo2"
            />
            <img
                alt="image"
                src="/playground_assets/default-img.svg"
                className="home-logo3"
            />
            <img
                alt="image"
                src="/playground_assets/default-img.svg"
                className="home-logo4"
            />
          </div>
        </div>
        <div className="home-pricing">
          <div className="home-heading-container3">
            <h1 className="home-text63 section-Heading">Ask for a quote</h1>
            <span className="home-text64 section-Text">
            Lorme ipsum dolor sit amet, consectetur adipiscing elit. Integer nec
            odio. Praesent libero.
          </span>
          </div>
          <div className="home-pricing-card-container">
            <div className="home-card3">
              <div className="home-card-heading">
                <span className="home-type anchor">Minimal</span>
                <span className="section-Heading">Basic</span>
              </div>
              <div className="home-card-content">
                <div className="home-feature">
                  <span className="card-Text">Feature one</span>
                  <span className="home-limit card-Text">TBD</span>
                </div>
                <div className="home-feature1">
                  <span className="card-Text">Feature two</span>
                  <span className="home-limit1 card-Text">TBD</span>
                </div>
                <div className="home-feature2">
                  <span className="card-Text">Feature three</span>
                  <span className="home-limit2 card-Text">UNLIMITED</span>
                </div>
                <div className="home-feature3">
                  <span className="card-Text">Feature four</span>
                  <span className="home-limit3 card-Text">UNLIMITED</span>
                </div>
                <button className="home-choose button anchor">CHOOSE</button>
              </div>
            </div>
            <div className="home-card4">
              <div className="home-card-heading1">
                <span className="home-type1 anchor">medium</span>
                <span className="section-Heading">Complex</span>
              </div>
              <div className="home-card-content1">
                <div className="home-container23">
                  <span className="card-Text">Feature one</span>
                  <span className="home-text66 card-Text">TBD</span>
                </div>
                <div className="home-container24">
                  <span className="card-Text">Feature two</span>
                  <span className="home-text68 card-Text">TBD</span>
                </div>
                <div className="home-container25">
                  <span className="card-Text">Feature three</span>
                  <span className="home-text70 card-Text">UNLIMITED</span>
                </div>
                <div className="home-container26">
                  <span className="card-Text">Feature four</span>
                  <span className="home-text72 card-Text">UNLIMITED</span>
                </div>
                <button className="home-button1 anchor button">CHOOSE</button>
              </div>
            </div>
            <div className="home-card5">
              <div className="home-card-heading2">
                <span className="home-type2 anchor">Premium</span>
                <span className="section-Heading">Professional</span>
              </div>
              <div className="home-card-content2">
                <div className="home-container27">
                  <span className="card-Text">Feature one</span>
                  <span className="home-text74 card-Text">TBD</span>
                </div>
                <div className="home-container28">
                  <span className="card-Text">Feature two</span>
                  <span className="home-text76 card-Text">TBD</span>
                </div>
                <div className="home-container29">
                  <span className="card-Text">Feature three</span>
                  <span className="home-text78 card-Text">UNLIMITED</span>
                </div>
                <div className="home-container30">
                  <span className="card-Text">Feature four</span>
                  <span className="home-text80 card-Text">UNLIMITED</span>
                </div>
                <button className="home-button2 anchor button">CHOOSE</button>
              </div>
            </div>
          </div>
        </div>
        <div className="home-section-separator3"></div>
        <div className="home-section-separator4"></div>
        <div className="home-faqs">
          <h2 className="home-text81 section-Heading">
            Check the most common questions
          </h2>
          <div className="home-content-container5">
            <div className="home-faq">
              <div className="home-question-container">
              <span className="home-question">
                Here you would write a frequently asked question?
              </span>
              </div>
              <div className="home-answer-container">
              <span className="home-answer card-Text">
                Here you would give the answer. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer nec odio. Praesent libero.
                Sed cursus ante dapibus diam.c Sed nisi. Nulla quis sem at nibh
                elementum imperdiet. Here you would give the answer. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Integer nec odio.
                Praesent libero. Sed cursus ante dapibus diam.c Sed nisi. Nulla
                quis sem at nibh elementum imperdiet.
              </span>
                <span className="card-Text">
                Here you would give the answer. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer nec odio. Praesent libero.
                Sed cursus ante dapibus diam.c Sed nisi. Nulla quis sem at nibh
                elementum imperdiet.
              </span>
              </div>
            </div>
            <div className="home-faq1">
              <div className="home-question-container1">
              <span className="home-question1">
                Here you would write a frequently asked question?
              </span>
              </div>
              <div className="home-answer-container1">
              <span className="home-answer2 card-Text">
                Here you would give the answer. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer nec odio. Praesent libero.
                Sed cursus ante dapibus diam.c Sed nisi. Nulla quis sem at nibh
                elementum imperdiet.
              </span>
                <span className="card-Text">
                Here you would give the answer. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer nec odio. Praesent libero.
              </span>
              </div>
            </div>
            <div className="home-faq2">
              <div className="home-question-container2">
              <span className="home-question2">
                Here you would write a frequently asked question?
              </span>
              </div>
              <div className="home-answer-container2">
              <span className="home-answer4 card-Text">
                Here you would give the answer. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer nec odio. Praesent libero.
                Sed cursus ante dapibus diam.c Sed nisi. Nulla quis sem at nibh
                elementum imperdiet.
              </span>
                <span className="card-Text">
                Here you would give the answer. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Integer nec odio. Praesent libero.
                Sed cursus ante dapibus diam.c Sed nisi. Nulla quis sem at nibh
                elementum imperdiet.
              </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-section-separator5"></div>
        <div className="home-subscribe">
          <img
              alt="image"
              src="https://firebasestorage.googleapis.com/v0/b/javax-969b9.appspot.com/o/Website%20Images%2FStudio-Project.png?alt=media&amp;token=f2f4b0af-06ba-4c5d-8768-77edb80f3422"
              className="home-image2"
          />
          <div className="home-container31">
            <div className="home-heading-container4">
              <h1 className="home-text82 section-Heading">
                Request A Consultation
              </h1>
              <span className="home-text83">
              <span className="section-Text home-text84">
                If you have a project requirement or an idea, fill out the form
                below, or send us an email and we will be in touch.
              </span>
              <br></br>
              <br></br>
            </span>
              <a href="mailto:info@javax.tech?subject=" className="home-link10">
                info@javax.tech
              </a>
              {formSubmitted? (
                  <div className="home-container32">
                    <h1 className="home-text87">Thank You For Your Inquiry,</h1>
                    <span>We will be in touch shortly.</span>
                  </div>
              ) : (
                  <form className="home-form">
                    <input
                        type="text"
                        id="contactname"
                        name="contactName"
                        required
                        placeholder="Name"
                        autoComplete="name"
                        className="home-textinput inputForm input"
                    />
                    <select
                        id="contactprojecttype"
                        name="contactprojecttype"
                        className="home-select inputForm input"
                    >
                      <option value="null">Select Inquiry Type</option>
                      <option value="Web Development">Web Development</option>
                      <option value="Mobile Application Development">
                        Mobile Application Development
                      </option>
                      <option value="API Integrations">API Integrations</option>
                      <option value="Custom Programming">Custom Programming</option>
                      <option value="Other">Other</option>
                    </select>
                    <input
                        type="email"
                        id="contactemail"
                        name="Email"
                        required
                        placeholder="Email"
                        autoComplete="email"
                        className="home-textinput1 inputForm input"
                    />
                    <textarea
                        id="contactdescription"
                        name="contactdescription"
                        placeholder="Tell us about your project needs"
                        className="home-textarea inputForm textarea input"
                    ></textarea>
                    <button
                        id="contactformsubmitbtn"
                        name="contactformsubmitbtn"
                        type="button"
                        className="home-button3 inputForm button"
                        onClick={handleFormSubmission}
                    >
                      Submit
                    </button>
                  </form>
              )}

            </div>
          </div>
        </div>
        <div className="home-section-separator6"></div>
        <div className="home-footer-container">
          <div className="home-footer">
            <div className="home-social-links">
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon34">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon36">
                <path d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon38">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </div>
            <div className="home-copyright-container">
              <svg viewBox="0 0 1024 1024" className="home-icon40">
                <path d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM506 390q-80 0-80 116v12q0 116 80 116 30 0 50-17t20-43h76q0 50-44 88-42 36-102 36-80 0-122-48t-42-132v-12q0-82 40-128 48-54 124-54 66 0 104 38 42 42 42 98h-76q0-14-6-26-10-20-14-24-20-20-50-20z"></path>
              </svg>
              <span className="anchor home-link11">
              javaX.tech Copyright, 2022 | All Rights Reserved
            </span>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Home
