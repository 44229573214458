import React from 'react'

import PropTypes from 'prop-types'

import './component.css'

const AppComponent = (props) => {
  return (
    <div className={`component-container ${props.rootClassName} `}>
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="component-image"
      />
      <h1 className="component-text">{props.heading}</h1>
      <span className="component-text1">{props.text}</span>
    </div>
  )
}

AppComponent.defaultProps = {
  image_alt: 'image',
  text: 'We will be in touch shortly.',
  heading: 'Thank You For Reaching Out',
  image_src:
    'https://images.unsplash.com/photo-1537498425277-c283d32ef9db?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fGNvbXB1dGVyfGVufDB8fHx8MTY2NjE5NjUxNw&ixlib=rb-4.0.3&w=500',
  rootClassName: '',
}

AppComponent.propTypes = {
  image_alt: PropTypes.string,
  text: PropTypes.string,
  heading: PropTypes.string,
  image_src: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default AppComponent
